import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class Home extends Component {
    static displayName = Home.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide"
        };
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "Udc6a06c1dfb0a2d100e2f2e8c97cc552";                        
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check member not exists
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberregischeck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //member exists
                                if (json.campaignEnd === "Y") {
                                    this.props.history.push('/CouponSoldOut3')
                                }
                                else {
                                    this.props.history.push('/Dashboard')
                                }
                            }
                            else if (json.responsecode === "No") {
                                //member exists - > not accept yet
                                if (json.campaignEnd === "Y") {
                                    this.props.history.push('/CouponSoldOut3')
                                }
                                else {
                                    this.props.history.push('/Home2')
                                }
                            }
                            else {
                                if (json.campaignEnd === "Y") {
                                    this.props.history.push('/CouponSoldOut3')
                                }
                                else {
                                    //member not exists - show this page
                                    this.setState({
                                        userLineID: LineuserId,
                                        Mobile: json.mobile,
                                        Email: json.email,
                                        Firstname: json.firstname,
                                        Lastname: json.lastname
                                    });
                                }
                            }
                        });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="position-relative">
                                <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                            </div>
                            <div className="form-enter mt-3 pb-0 ps-1 pe-1" style={{ minHeight: '730px' }}>
                                <div className="ps-0 pe-0 mt-0 mb-2">
                                    <img src={require("./media/img-kv-page-section-03.png")} alt="Happy Holi-yays" className="img-fluid mb-0" />
                                </div>
                                <div className="position-relative mt-3">
                                    <div className="row m-0 p-1 justify-content-center index2">
                                        <div className="col-6 p-0 ps-2 pe-1 text-center">
                                            <input type="button" className="btn mb-3 btn-white2 w-100 shadow-none" defaultValue="ยกเลิก" onClick={this.closeLIFF} />
                                        </div>
                                        <div className="col-6 p-0 ps-1 pe-2 text-center">
                                            <Link to="/HomeRegis" className="btn mb-3 btn-register-2 w-100 shadow-none">ร่วมกิจกรรม</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '-5px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} >
                                        24116-10:45
                                    </div>
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }
            </div>

        );
    }
}
