import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

import myDoc from './media/document.svg'

const liff = window.liff;

export class HomeRegis extends Component {
    static displayName = HomeRegis.name;

    constructor(props) {
        super(props);

        this.state = {
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            ModelCssinner03: "d-none",
            ModelCssinner04: "d-none",
            ModelCssinner05: "d-none",
            ModelCssinner06: "d-none",
            ModelCssButton: "btn btn-lg mt-2 mb-0 btn-register-2"
        };

        this.onInputchange = this.onInputchange.bind(this);
        this.onSubmitForm = this.onSubmitForm.bind(this);

        this.closeModal = this.closeModal.bind(this);
    }

    closeModal() {
        this.setState({
            ModelCss01: "popuphide",
            ModelCssinner01: "d-none",
            ModelCssinner02: "d-none",
            ModelCssinner03: "d-none",
            ModelCssinner04: "d-none",
            ModelCssinner05: "d-none",
            ModelCssinner06: "d-none"
        });
    }

    onInputchange(event) {
        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;
        this.setState({
            [name]: value
        });

        console.log(this.state);
    }

    async onSubmitForm() {
        let isthisvalid = "yes";

        if (!this.state.firstname) {
            this.setState({
                ModelCssinner01: ""
            });

            isthisvalid = "no";
        }
        if (!this.state.lastname) {
            this.setState({
                ModelCssinner02: ""
            });

            isthisvalid = "no";
        }
        if (!this.state.mobile) {
            this.setState({
                ModelCssinner03: ""
            });

            isthisvalid = "no";
        } else if (this.state.mobile.length !== 10) {
            this.setState({
                ModelCssinner03: ""
            });

            isthisvalid = "no";
        }

        const validateEmail = (email) => {
            return email.match(
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            );
        };

        if (!this.state.email) {
            this.setState({
                ModelCssinner04: ""
            });

            isthisvalid = "no";
        } else if (!validateEmail(this.state.email)) {
            this.setState({
                ModelCssinner04: ""
            });

            isthisvalid = "no";
        }

        if (!this.state.ans01) {
            this.setState({
                ModelCssinner05: ""
            });

            isthisvalid = "no";
        }

        console.log(isthisvalid);

        if (isthisvalid === "no") {
            this.setState({
                ModelCss01: "popup"
            });
        }
        else {
            if (!this.state.ans02) {
                // POST request using fetch inside useEffect React hook                    
                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            UserId: this.state.userLineID,
                            Firstname: this.state.firstname,
                            Lastname: this.state.lastname,
                            Email: this.state.email,
                            Mobile: this.state.mobile,
                            Ans01: this.state.ModelCssinner05,
                            Ans02: "No"
                        }
                    )
                };

                const response = await fetch('/api/memberregis1', requestOptions);
                const data = await response.json();

                if (data.responsecode === "OK") {
                    this.props.history.push('/HomeThank')
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: data.responsemessage
                        }
                    });
                }
            }
            else {
                // POST request using fetch inside useEffect React hook

                //disable submit button
                this.setState({
                    ModelCssButton: "btn btn-lg mt-2 mb-0 btn-register-2-dis"
                });

                const requestOptions = {
                    method: 'POST',
                    headers: { 'Content-Type': 'application/json' },
                    body: JSON.stringify(
                        {
                            UserId: this.state.userLineID,
                            Firstname: this.state.firstname,
                            Lastname: this.state.lastname,
                            Email: this.state.email,
                            Mobile: this.state.mobile,
                            Ans01: this.state.ModelCssinner05,
                            Ans02: "Yes"
                        }
                    )
                };

                const response = await fetch('/api/memberregis1', requestOptions);
                const data = await response.json();

                if (data.responsecode === "OK") {
                    this.props.history.push('/HomeThank')
                }
                else {
                    this.props.history.push({
                        pathname: "/Mistake",
                        state: {
                            ErrorTxt: data.responsemessage
                        }
                    });
                }
            }
        }
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            this.props.history.push('/NotInLine');

            //let LineuserId = "x7";                                    
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    //check member not exists
                    const requestOptions = {
                        method: 'POST',
                        headers: { 'Content-Type': 'application/json' },
                        body: JSON.stringify(
                            {
                                UserId: LineuserId
                            }
                        )
                    };

                    await fetch('/api/memberregischeck', requestOptions)
                        .then(response => response.json())
                        .then(json => {
                            if (json.responsecode === "OK") {
                                //member exists
                                this.props.history.push('/Dashboard')
                            }
                            else if (json.responsecode === "No") {
                                //member exists - > not accept yet
                                if (json.campaignEnd === "Y") {
                                    this.props.history.push('/CouponSoldOut3')
                                }
                                else {
                                    this.props.history.push('/Home2')
                                }
                            }
                            else {
                                if (json.campaignEnd === "Y") {
                                    this.props.history.push('/CouponSoldOut3')
                                }
                                else {
                                    //member not exists - show register check form
                                    this.setState({
                                        userLineID: LineuserId,
                                        Mobile: json.mobile,
                                        Email: json.email,
                                        Firstname: json.firstname,
                                        Lastname: json.lastname
                                    });
                                }
                            }
                        });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home position-relative">
                {
                    (this.state.userLineID && this.state.userLineID !== '')
                        ?
                        <>
                            <div className="position-relative">
                                <div className="bg-top"><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                            </div>
                            <div className="head-nav text-center p-3 pb-0">
                                <img src={require("./media/logo-stb-24.png")} width={50} alt="กิจกรรม Happy Holi-yays" className="img-fluid mt-1" />
                            </div>
                            <div className="form-enter pt-3 mt-4">
                                <h2 className="h3 text-center mb-4">
                                    <img src={require("./media/head-s4.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" style={{ width: '70%', marginTop: '15px' }} />
                                </h2>                            
                                <form id="myform">
                                    <fieldset className="fieldset-form pt-0 ps-3 pe-3">
                                        <div className="form-group">
                                            <label>ชื่อ<span className="text-label">*</span></label>
                                            <input type="text" className="form-control" maxLength={35} required name="firstname" defaultValue={this.state.firstname} onChange={this.onInputchange} />
                                        </div>
                                        <div className="form-group">
                                            <label>นามสกุล<span className="text-label">*</span></label>
                                            <input type="text" className="form-control" maxLength={35} required name="lastname" defaultValue={this.state.lastname} onChange={this.onInputchange} />
                                        </div>
                                        <div className="form-group">
                                            <label>เบอร์มือถือ<span className="text-label">*</span></label>
                                            <input type="tel" className="form-control" maxLength="10" required name="mobile" defaultValue={this.state.mobile} onChange={this.onInputchange} />
                                        </div>
                                        <div className="form-group mb-2">
                                            <label>อีเมล<span className="text-label">*</span></label>
                                            <input type="email" className="form-control" required name="email" defaultValue={this.state.email} onChange={this.onInputchange} />
                                            <label className="label-warning-email" style={{ fontSize: '16px', fontWeight: 400 }}>กรอกอีเมลที่ใช้สมัครสมาชิก Starbucks<sup style={{ fontSize: '12px' }}>®</sup>  Rewards <br />
                                                (ถ้าไม่มี กรอกอีเมลที่สามารถติดต่อได้)</label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="ans01" defaultValue={this.state.ans01} onChange={this.onInputchange} />
                                            <label className="form-check-label text-darkblue"><small>รับทราบ <Link to="/term" className="text-darkblue" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>ข้อกำหนดและเงื่อนไขของกิจกรรม</strong></Link></small></label>
                                        </div>
                                        <div className="form-check">
                                            <input className="form-check-input" type="checkbox" name="ans02" defaultValue={this.state.ans02} onChange={this.onInputchange} />
                                            <label className="form-check-label text-darkblue"><small>รับทราบและตกลงตาม <Link to="/faqobj" className="text-darkblue" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>วัตถุประสงค์ในการให้ความยินยอม</strong><br />
                                            </Link>และรับทราบ <Link to={{ pathname: "https://www.starbucks.co.th/th/online-policies/privacy-notice/" }} target="_blank" className="text-darkblue" style={{ textDecoration: 'underline' }}><strong style={{ fontWeight: 500 }}>นโยบายข้อมูลส่วนบุคคล</strong></Link></small></label>
                                        </div>
                                        <div className="row text-center mt-3 mb-0 justify-content-center">
                                            <div className="col-6 p-0">
                                                <div className="d-grid">                                                    
                                                    <input type="button" className={this.state.ModelCssButton} onClick={this.onSubmitForm} defaultValue="ลงทะเบียน" />
                                                </div>
                                            </div>
                                        </div>

                                        <div>
                                            {/*Modal*/}
                                            <div id="popup1" className={this.state.ModelCss01}>
                                                <Link to="#" onClick={this.closeModal} className="close-popup">×</Link>
                                                <div className="text-center mt-3 mb-2">
                                                    <img src={myDoc} alt="Warning..." width={100} className="img-fluid" />
                                                </div>
                                                <h5 className="text-center">กรุณากรอกข้อมูลให้ถูกต้องและครบถ้วน</h5>
                                                <ul className="text-start">
                                                    <li className={this.state.ModelCssinner01}>ชื่อ</li>
                                                    <li className={this.state.ModelCssinner02}>นามสกุล</li>
                                                    <li className={this.state.ModelCssinner03}>เบอร์มือถือ</li>
                                                    <li className={this.state.ModelCssinner04}>อีเมล</li>
                                                    <li className={this.state.ModelCssinner05}>กดรับทราบ ข้อกำหนดและเงื่อนไขของกิจกรรม</li>
                                                </ul>
                                                <div className="text-center mt-3 mb-2">
                                                    <div onClick={this.closeModal} className="btn btn-lg mb-0 btn-register-2">ตกลง</div>
                                                </div>
                                            </div>
                                        </div>
                                    </fieldset>
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer"><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                        :
                        <>
                            <div className="form-enter mt-0">
                                <form>
                                    <div className="text-center" style={{ height: '220px' }} />
                                    <div className="text-center mt-5 pt-5 mb-5 pb-5">
                                        <h3 className="text-white">LOADING ...</h3>
                                        <img src={require("./media/loading-gif.gif")} alt="Loading..." width={80} className="img-fluid" />
                                    </div>
                                    <div style={{ height: '100px' }} />
                                </form>
                            </div>
                            <div className="position-relative">
                                <div className="bg-footer" style={{ bottom: '0px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>
                            </div>
                        </>
                }
            </div>

        );
    }
}
