import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class Faq extends Component {
    static displayName = Faq.name;

    constructor(props) {
        super(props);

        this.state = {};
    }


    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId,
                        pictureUrl: LineuserPicture
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <h2 className="h5 text-center mt-4 pt-2 mb-3">
                    <img src={require("./media/head-faq.png")} alt="คำถามที่พบบ่อย" className="img-fluid" style={{ width: '70%' }} />
                </h2>
                <div className="box-bg-white-radius-more pt-4 shadow">
                    <div className="box-content-term" style={{ height: '450px', maxHeight: '450px' }}>
                        <div className="small pt-0 ps-3 pe-3" style={{ color: '#c22939' }}>
                            <ol style={{ paddingLeft: '10px' }}>
                                <li><span style={{ fontWeight: 500 }}>วิธีการเข้าร่วมกิจกรรมทำอย่างไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>เพิ่มเพื่อน @StarbucksThailand บน Application LINE ลงทะเบียนเข้าร่วมกิจกรรมโดยระบุ ชื่อ-นามสกุล เบอร์โทรศัพท์ และอีเมล พร้อมทั้งรับทราบข้อตกลงเงื่อนไขในการเข้าร่วมกิจกรรม, เงื่อนไขของโปรโมชั่น  และนโยบายความเป็นส่วนตัว กรณีเคยลงทะเบียนรับ Starbucks Privilege E-Coupon จากกิจกรรมต่างๆ บน Application LINE จะไม่ต้องกรอกข้อมูลเพื่อลงทะเบียนซ้ำ</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากปัจุบันได้ทำการยกเลิกการเป็นเพื่อน (Block) @StarbucksThailand แล้วจะสามารถเข้าร่วมกิจกรรมได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>สามารถเข้าร่วมกิจกรรมนี้ได้  เพียงแค่ทำการปลด Block (unblock)<strong> </strong>และเพิ่ม Starbucks<strong> </strong>เป็นเพื่อนอีกครั้ง</span></li>
                                <li><span style={{ fontWeight: 500 }}>สิทธิพิเศษมีอะไรบ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>เมื่อซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไป 1 แก้ว รับแสตมป์ 1 ดวง ผ่าน LINE Starbucks Thailand สะสมครบ 5 ดวง รับ Starbucks Privilege E-Coupon ในรูปแบบ Digital Coupon สำหรับซื้อเครื่องดื่มขนาดใดก็ได้ 1 แก้ว ฟรี 1 แก้ว จำนวน 1 ใบ โดยสามารถสะสมแสตมป์ได้สูงสุด 10 ดวง เพื่อรับคูปองจำนวน 2 ใบ</span></li>
                                <li><span style={{ fontWeight: 500 }}>สิทธิพิเศษนี้มีถึงวันที่เท่าไหร่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>สามารถสะสมแสตมป์และรับ Starbucks Privilege E-Coupon ในรูปแบบ Digital Coupon สำหรับซื้อเครื่องดื่มขนาดใดก็ได้ 1 แก้ว ฟรี 1 แก้ว จำนวน 1 ใบ เริ่มตั้งแต่วันที่ 23 กันยายน 2567 – 6 ตุลาคม 2567  และสามารถใช้ Starbucks Privilege E-Coupon ได้ตั้งแต่วันที่ 23 กันยายน 2567  – 13 ตุลาคม 2567 เวลา 23.59 น.</span></li>
                                <li><span style={{ fontWeight: 500 }}>เครื่องดื่มที่สามารถสะสมแสตมป์ได้มีอะไรบ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>เครื่องดื่มสตาร์บัคส์ทุกประเภท รวมถึงเครื่องดื่ม Customization, เครื่องดื่มที่ทำจากกาแฟ Starbucks Reserve™, เครื่องดื่มประเภท Nitro และเครื่องดื่มไอศกรีมทุกประเภท  ยกเว้น เครื่องดื่มบรรจุขวด น้ำผลไม้สกัดเย็น และเครื่องดื่มแอลกอฮอล์ทุกประเภท  ซึ่งเครื่องดื่มที่สะสมแสตมป์จะต้องเป็นเครื่องดื่มที่ไม่ร่วมโปรโมชั่นใดๆ  รวมถึงไม่สามารถใช้ร่วมกับ Starbucks &nbsp;Privilege E-Coupon<strong> </strong>ซื้อเครื่องดื่ม 1 แก้ว ฟรี 1 แก้ว<strong> </strong>รวมถึงต้องไม่มีการใช้ Voucher อื่นๆ อยู่ในใบเสร็จนั้น ยกเว้นส่วนลดแก้วส่วนตัว (Personal Cup)</span>
                                </li>
                                <li><span style={{ fontWeight: 500 }}>จะได้รับ Starbucks Privilege E-Coupon ได้อย่างไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไป เพิ่มเพื่อน @StarbucksThailand บน Application LINE ลงทะเบียนเข้าร่วมกิจกรรมในข้อ 1 ให้เรียบร้อย นำใบเสร็จที่ซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไปที่ร้านสตาร์บัคส์ นำมาสแกน QR Code หรือกรอกรหัส QR Code ที่ท้ายใบเสร็จ เพื่อตรวจสอบสิทธิ์ ซึ่งใบเสร็จจะต้องออกโดยสตาร์บัคส์ผ่านระบบการขายที่หน้าร้าน (POS) และเป็นไปตามเงื่อนไขที่บริษัทฯ กำหนดเท่านั้น ระบบจะทำการตรวจสอบภายใน 5 ชั่วโมง หากถูกต้องจะได้รับคูปองโดยอัตโนมัติ</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon ใช้เวลาตรวจสอบนานเท่าไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ท่านจะได้รับคูปองภายใน 5 ชั่วโมงโดยอัตโนมัติผ่านทาง Line โดยสามารถตรวจสอบได้ที่เมนู “คูปองของฉัน”</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากใบเสร็จขึ้นสถานะไม่ผ่านเงื่อนไข และต้องการให้ตรวจสอบข้อมูลเพิ่มเติม จะทำอย่างไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ใช้ใบเสร็จตัวจริงที่มีการซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไป ติดต่อสอบถามข้อมูลเพิ่มเติมได้ที่ ฝ่ายดูแลลูกค้า 02-339-0996 ทุกวันจันทร์ – อาทิตย์ ตั้งแต่เวลา 9.00 น. – 18.00 น. หากท่านไม่ได้เก็บใบเสร็จตัวจริงเพื่อใช้ในการตรวจสอบ ทางบริษัทฯ ขอสงวนสิทธิ์ในตรวจสอบข้อมูลทุกกรณี</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon มีอายุการใช้งานนานเท่าไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>คูปองมีอายุการใช้งานตั้งแต่ 23 กันยายน 2567  – 13 ตุลาคม 2567 และคูปองจะหมดอายุการใช้งานในวันที่ 13 ตุลาคม 2567 เวลา 23.59 น.</span></li>
                                <li><span style={{ fontWeight: 500 }}>จำกัดจำนวน Starbucks Privilege E-Coupon ต่อ 1 บัญชี LINE หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>จำกัดสิทธิ์คูปอง 2 ใบต่อ 1 บัญชี LINE โดยสามารถส่งข้อมูลใบเสร็จที่มีการซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12 ออนซ์ขึ้นไป เพื่อรับคูปองได้ตั้งแต่วันที่ 23 กันยายน 2567 – 6 ตุลาคม 2567</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon สามารถแคปเจอร์หน้าจอ เพื่อใช้สิทธิ์ส่วนลดได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ไม่สามารถแคปเจอร์หน้าจอมาใช้งาน เมื่อต้องการใช้สิทธิ์ส่วนลด Starbucks Privilege E-Coupon โปรดแสดงหน้าคูปองกับพนักงานก่อนทำการสั่งซื้อทุกครั้ง และสแกน QR Code ที่ระบบการขายที่หน้าร้าน (POS)</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากกดใช้ Starbucks Privilege E-Coupon และระบบปรากฏคำว่า “Redeemed” (ใช้คูปองแล้ว) โดยตั้งใจหรือไม่ตั้งใจ สามารถยกเลิกการใช้คูปอง เพื่อให้กลับมาพร้อมใช้ได้งานหรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>Starbucks Privilege E-Coupon แต่ละใบ สามารถใช้งานได้เพียงครั้งเดียวเท่านั้น หากสแกนยืนยันการใช้คูปองแล้วจะไม่สามารถใช้คูปองที่ปรากฏคำว่า “Redeemed” (ใช้คูปองแล้ว) ได้อีกในทุกกรณี</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon สามารถส่งให้เพื่อนใช้งานได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>Starbucks Privilege E-Coupon สามารถแชร์ให้เพื่อนได้ โดยการส่งผ่านทาง Application LINE เมนู “แชร์ให้เพื่อน” หากท่านหรือเพื่อนมีการกดใช้คูปองจนปรากฏคำว่า “Redeemed” (ใช้คูปองแล้ว) จะสามารถใช้ได้เพียงครั้งเดียวเท่านั้น</span></li>
                                <li><span style={{ fontWeight: 500 }}>Starbucks Privilege E-Coupon ใช้ร่วมกับส่วนลดโปรโมชั่นอื่นๆ ของร้านได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>คูปองส่วนลดไม่สามารถใช้ร่วมกับส่วนลดโปรโมชั่นอื่นๆ ของร้านได้</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากได้รับ Starbucks Privilege E-Coupon ไปแล้ว และยกเลิกการใช้งาน Account บัญชีผู้ใช้งาน LINE ID คูปองยังสามารถใช้งานได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>คูปองที่ได้รับไปแล้วจะไม่สามารถใช้งานได้ และไม่สามารถนำกลับมาใช้งานที่ Application LINE ใหม่ของท่านได้เช่นกัน</span></li>
                                <li><span style={{ fontWeight: 500 }}>หากได้รับ Starbucks Privilege E-Coupon ไปแล้ว และทำการ Block @Starbucks Thailand คูปองยังสามารถใช้งานได้หรือไม่</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>หากท่านทำการกดปลด Block (Unblock) @Starbucks Thailand ท่านสามารถกลับมาใช้งานคูปองได้อีกครั้ง และคูปองที่ได้รับไปแล้วจะยังคงอยู่จนถึงระยะเวลาสิ้นสุดโปรโมชั่น</span></li>
                                <li><span style={{ fontWeight: 500 }}>สามารถติดต่อสอบถามข้อมูลของโปรโมชั่นนี้ผ่านช่องทางใดได้บ้าง</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>หากมีข้อสงสัยหรือต้องการสอบถามเงื่อนไขในกิจกรรม กรุณาติดต่อได้ที่ ฝ่ายดูแลลูกค้า 02-339-0996  ทุกวันจันทร์ – อาทิตย์ ตั้งแต่เวลา 9.00 น. – 18.00 น. Facebook : Starbucks Thailand<br />
                                        Email: customercomment@coffee-concepts.co.th</span></li>
                                <li><span style={{ fontWeight: 500 }}>สามารถ Share Coupon ให้เพื่อนได้อย่างไร</span><br />
                                    <strong className="text-decoration-underline" style={{ color: '#494db6' }}>ตอบ</strong> <span style={{ color: '#494848', fontWeight: 300 }}>ท่านสามารถกดปุ่ม “แชร์เพื่อน” ในหน้าคูปอง และเลือกรายชื่อเพื่อนที่ต้องการส่งให้ หลังจากนั้นระบบจะส่งข้อความพร้อมลิงก์กดรับคูปองผ่าน Line ไปยังรายชื่อนั้น</span>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0 justify-content-center index2">
                    <div className="col-6 mt-3 text-center">
                        <div className="d-grid">
                            <Link to="/" className="btn mt-0 mb-3 btn-register-2">ปิด</Link>
                        </div>
                    </div>
                </div>
                {/*<div className="position-relative">*/}
                {/*    <div className="bg-footer" style={{ bottom: '-85px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>*/}
                {/*</div>*/}
            </div>
        );
    }
}
