import React from 'react';
import { Link } from 'react-router-dom';
import myLogo from './media/logo_Starbucks.svg';

const NavMenu = ({ pictureUrl, Firstname, Lastname }) => {
    return (
        <>
            <div className="position-relative">
                <div className="bg-top"><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
            </div>
            <nav className="navbar bg-white pt-0 ps-3 pb-0" aria-label="First navbar">
                <div className="container">
                    <div className="row w-100">
                        <div className="col-6">
                            <div className="p-1"></div>
                        </div>
                        <div className="col-6 pe-0 text-end">
                            <button className="navbar-toggler pe-1" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasRight" aria-controls="offcanvasRight">
                                <img src={require("./media/icon-menu.png")} alt="Menu" width={40} className="img-fluid" />
                            </button>
                        </div>
                    </div>
                </div>
            </nav>
            <div className="offcanvas offcanvas-end" tabIndex={-1} id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
                <div className="offcanvas-header">
                    <button type="button" className="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close" />
                </div>
                <div className="offcanvas-body ps-2 pe-2">
                    <div className="text-center mt-1">
                        <Link to="/"><img src={myLogo} alt="Menu" width={60} className="img-fluid" /></Link>
                    </div>
                    <div className="row p-2 mb-3">
                        <div className="col-3 pe-1">
                            <img src={pictureUrl} alt="Profile" width={60} className="img-fluid rounded-circle img-profile" />
                        </div>
                        <div className="col-9 ps-1">
                            <h3 className="h5 mt-3" style={{ fontSize: '90%' }}>{Firstname} {Lastname}</h3>
                        </div>
                    </div>
                    <ul className="list-unstyled ms-2">
                        <li className="mb-2">
                            <Link to="/faq"><img src={require("./media/icon-faq1.png")} alt="คำถามที่พบบ่อย" width={25} className="img-fluid rounded-circle" /> คำถามที่พบบ่อย</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="/term"><img src={require("./media/icon-faq2.png")} alt="ข้อกำหนดและเงื่อนไข" width={25} className="img-fluid rounded-circle" /> ข้อกำหนดและเงื่อนไข</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="/"><img src={require("./media/icon-home2.png")} alt="หน้าหลัก" width={25} className="img-fluid rounded-circle" /> หน้าหลัก</Link>
                        </li>
                    </ul>
                </div>
            </div>
        </>
    );
};

export default NavMenu;