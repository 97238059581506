import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import { publicLiffId } from './Aconstants';

const liff = window.liff;

export class Term extends Component {
    static displayName = Term.name;

    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        this.populateProfile();
    }

    closeLIFF() {
        liff.closeWindow();
    }

    async populateProfile() {
        if (!liff.isInClient()) {
            //this.props.history.push('/NotInLine');

            //let LineuserId = "x1";
        } else {
            liff.init({
                liffId: publicLiffId
            }).then(async () => {
                let LineuserId = "";
                let LineuserPicture = "";

                await liff.getProfile()
                    .then(profile => {
                        LineuserId = profile.userId;
                        LineuserPicture = profile.pictureUrl;
                    })
                    .catch((err) => {
                        LineuserId = "Err";
                    });

                if (LineuserId === "Err") {
                    this.props.history.push('/MistakeLine')
                }
                else {
                    //action - start
                    this.setState({
                        userLineID: LineuserId,
                        pictureUrl: LineuserPicture
                    });
                    //action - end
                }
            });
        }
    }

    render() {
        return (
            <div className="form-signin shadow bg-home">
                <div className="position-relative">
                    <div className="bg-top" style={{ top: '20px' }}><img src={require("./media/bg-top.png")} alt="กิจกรรม Happy Holi-yays" className="img-fluid" /></div>
                </div>
                <h2 className="h5 text-center mt-4 pt-2 mb-3">
                    <img src={require("./media/head-term.png")} alt="ข้อกำหนดและเงื่อนไขของกิจกรรม" className="img-fluid" style={{ width: '70%' }} />
                </h2>
                <div className="box-bg-white-radius-more pt-4 shadow">
                    <div className="box-content-term" style={{ height: '450px', maxHeight: '450px' }}>
                        <div className="small pt-0 ps-3 pe-3" style={{ color: '#000000' }}>
                            <ol style={{ paddingLeft: '10px' }}>
                                <li>โปรโมชั่นนี้  จัดขึ้นที่ Application LINE บน LINE  @StarbucksThailand โดยเงื่อนไขที่จัดทำขึ้นสำหรับผู้ที่ซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้  ขนาด 12 ออนซ์ขึ้นไป 1 แก้ว รับแสตมป์ 1 ดวง ผ่าน LINE Starbucks Thailand สะสมครบ 5 ดวง รับ Starbucks Privilege E-Coupon ในรูปแบบ  Digital Coupon สำหรับซื้อเครื่องดื่มขนาดใดก็ได้ 1 แก้ว ฟรี  1 แก้ว จำนวน 1 ใบ โดยสามารถสะสมแสตมป์ได้สูงสุด 10 ดวง เพื่อรับคูปองจำนวน 2  ใบ&nbsp; เริ่มตั้งแต่วันที่ 23 กันยายน 2567 –  6 ตุลาคม 2567 ได้ที่ร้านสตาร์บัคส์ทุกสาขา, บริการ Mobile  Order-ahead &amp; Pick Up at Store, บริการ Mobile Order to Table และบริการ Drive  Thru (ยกเว้นบริการจัดส่งผ่าน Delivery ทุกช่องทาง)  และสามารถใช้ Starbucks Privilege E-Coupon ได้ตั้งแต่วันที่  23 กันยายน 2567 – 13 ตุลาคม 2567 เวลา 23.59 น. </li>
                                <li>ผู้ร่วมกิจกรรมจะต้องเป็นเพื่อนกับ  Starbucks บน Application LINE และลงทะเบียนเพื่อร่วมกิจกรรม  โดยระบุ ชื่อ-นามสกุล เบอร์โทรศัพท์ และอีเมล  พร้อมทั้งรับทราบข้อตกลงเงื่อนไขในการเข้าร่วมกิจกรรม, เงื่อนไขของโปรโมชั่น  และนโยบายข้อมูลส่วนบุคคล</li>
                                <li>หากผู้เข้าร่วมกิจกรรมได้ทำการยกเลิกการเป็นเพื่อน  (Block) กับ Starbucks และท่านมีความประสงค์ในการเข้าร่วมกิจกรรมนี้ท่านจะต้องทำการปลดบล็อค  และเพิ่ม Starbucks เป็นเพื่อนอีกครั้ง</li>
                                <li>Starbucks Privilege E-Coupon จะรับได้ที่ Application  LINE บน LINE @StarbucksThailand เท่านั้น  โดยนำใบเสร็จที่ซื้อเครื่องดื่มสตาร์บัคส์ประเภทใดก็ได้ ขนาด 12  ออนซ์ขึ้นไปที่ร้านสตาร์บัคส์ นำมาสแกน QR Code หรือกรอกรหัส  QR Code ท้ายใบเสร็จ เพื่อทำการตรวจสอบสิทธิ์บน Application  LINE บน LINE @StarbucksThailand ชื่อเมนู  “กรอกข้อมูลใบเสร็จ” ใบเสร็จจะต้องออกโดยสตาร์บัคส์ผ่านระบบการขายที่หน้าร้าน (POS)  และเป็นไปตามเงื่อนไขที่บริษัทฯ กำหนดเท่านั้น</li>
                                <li>หลังจากกรอกข้อมูลในข้อที่  4 เป็นที่เรียบร้อยแล้ว หากท่านได้รับสิทธิ์ตามเงื่อนไขที่กำหนด ท่านจะได้รับคูปองภายใน 5 ชั่วโมง โดยอัตโนมัติ ผ่านทาง Application LINE ทั้งนี้ระยะเวลาการจัดส่งคูปองเป็นระยะเวลาโดยประมาณเท่านั้น  ท่านรับทราบและยอมรับว่าระยะเวลาที่ท่านจะได้รับคูปองนั้น  อาจเกินกว่าระยะเวลาที่ได้ประมาณไว้  ซึ่งอาจเกิดจากการมีผู้เข้าร่วมกิจกรรมเป็นจำนวนมาก เป็นต้น</li>
                                <li>สถานะการตรวจสอบที่ระบุบน  Application LINE บน LINE @StarbucksThailand ชื่อเมนู “เช็คสถานะใบเสร็จ” จะมี 3 สถานะ โดยมีความหมายดังนี้<br />
                                    <br />
                                    6.1. กำลังตรวจสอบ ความหมาย  ระบบได้รับข้อมูลที่ท่านกรอกเข้ามาเป็นที่เรียบร้อยแล้ว อยู่ในขั้นตอนการตรวจสอบ  ว่าใบเสร็จที่ท่านได้ทำการกรอกตามข้อที่ 4 นั้น ถูกต้องตามเงื่อนไขหรือไม่ <br />
                                    <br />
                                    6.2. ตรวจสอบสำเร็จ ความหมาย  ระบบได้ทำการตรวจสอบใบเสร็จที่ท่านได้กรอกข้อมูลเข้ามาในข้อที่ 4  เป็นที่เรียบร้อยแล้ว ใบเสร็จของท่านตรงตามเงื่อนไขที่กำหนด  ท่านจะได้รับแสตมป์สะสม เพื่อรับ Starbucks  Privilege E-Coupon โดยท่านสามารถกดตรวจสอบได้ที่เมนู “คูปองของฉัน”<br />
                                    <br />
                                    6.3. ไม่ผ่านเงื่อนไข ความหมาย  ระบบได้รับข้อมูลที่ท่านได้กรอกข้อมูลเข้ามาในข้อที่ 4 เป็นที่เรียบร้อยแล้ว  และได้ทำการตรวจสอบว่าใบเสร็จของท่านไม่อยู่ในเงื่อนไขของการได้รับ Starbucks Privilege E-Coupon<br />
                                    <br />
                                    <p style={{ paddingLeft: '35px' }}>6.3.1  หากมีข้อสงสัยเกี่ยวกับการตรวจสอบใบเสร็จที่มีสถานะไม่ผ่านเงื่อนไข  กรุณาใช้ใบเสร็จตัวจริง เพื่อติดต่อสอบถามข้อมูลเพิ่มเติมที่ ฝ่ายดูแลลูกค้า  02-339-0996 ทุกวันจันทร์ – อาทิตย์ ตั้งแต่เวลา 9.00 น. – 18.00 น. <br />
                                    </p>
                                    <p style={{ paddingLeft: '35px' }}>6.3.2  การตรวจสอบใบเสร็จที่มีสถานะไม่ผ่านเงื่อนไข  จำเป็นต้องใช้ข้อมูลบนใบเสร็จตัวจริงที่ระบุข้อมูลในข้อที่ 4 อย่างครบถ้วน  หากท่านไม่ได้เก็บใบเสร็จตัวจริง เพื่อใช้อ้างอิงในการตรวจสอบ ทางบริษัทฯ  ขอสงวนสิทธิ์ ไม่ตรวจสอบข้อมูลใบเสร็จที่มีสถานะไม่ผ่านเงื่อนไขทุกกรณี</p></li>
                                <li>กรณีที่ท่านได้รับ  Starbucks Privilege E-Coupon ไปแล้ว  และได้ทำการยกเลิกการใช้งาน Application LINE คูปองที่ท่านได้รับไปแล้วนั้น  จะไม่สามารถใช้งานได้และไม่สามารถนำกลับมาใช้งานที่ Application LINE บน LINE @StarbucksThailand ใหม่ของท่านได้เช่นกัน</li>
                                <li>Starbucks Privilege E-Coupon มีเงื่อนไขคูปองดังนี้<br />
                                    <br />
                                    8.1. คูปองซื้อเครื่องดื่มขนาดใดก็ได้  1 แก้ว ฟรี 1 แก้ว <br />
                                    <br />
                                    <ul>
                                        <li>ผู้ที่ได้รับสิทธิ์  สามารถใช้คูปองได้ที่สตาร์บัคส์ทุกสาขาทั่วประเทศ, สาขาสนามบินทุกสาขา รวมถึงร้านสตาร์บัคส์ รีเสิร์ฟ เจ้าพระยา  ริเวอร์ฟร้อนท์ ไอคอนสยาม ชั้น 7 ยกเว้นบริการ Drive Thru, บริการ  Mobile Order-ahead &amp; Pick Up at Store, บริการ Mobile Order to Table และบริการจัดส่งผ่าน Delivery ทุกช่องทาง</li>
                                        <li>คูปองนี้ไม่สามารถใช้ร่วมกับส่วนลดแก้วส่วนตัว  (Personal Cup) และส่วนลดอื่นๆ</li>
                                        <li>ลูกค้าที่เข้าร่วมกิจกรรมจะได้รับคูปองส่วนลดผ่านช่องทาง  LINE @StarbucksThailand เพื่อใช้เป็นส่วนลด  ซื้อเครื่องดื่มขนาดใดก็ได้ 1 แก้ว ฟรี 1 แก้ว  (รับฟรีเครื่องดื่มแก้วที่ราคาน้อยที่สุด)</li>
                                        <li>1  คูปองสามารถใช้ได้เพียง 1 สิทธิ์เท่านั้น</li></ul></li>
                                <li>คูปองส่วนลดที่ท่านได้รับ  สามารถส่งต่อให้เพื่อนของท่านได้ โดยการส่งผ่านทาง Application  LINE จากบัญชีผู้ใช้ (user account) ในชื่อเมนู  “แชร์ให้เพื่อน” คูปองจะสามารถใช้ได้เพียงครั้งเดียวเท่านั้น  ซึ่งจะเป็นไปตามเงื่อนไข 1 คูปองสามารถใช้ได้เพียง 1 สิทธิ์เท่านั้น</li>
                                <li>คูปองมีอายุการใช้งานตั้งแต่วันที่  23 กันยายน 2567 – 13 ตุลาคม 2567 และคูปองจะหมดอายุการใช้งานในวันที่ 13 ตุลาคม  2567 เวลา 23.59 น. </li>
                                <li>การแจกคูปอง  1 บัญชีผู้ใช้ (user account) สามารถกดรับสิทธิ์ได้ตลอดระยะเวลากิจกรรม  โดยเงื่อนไขต้องตรงตามข้อที่ 4</li>
                                <li>เมื่อต้องการใช้สิทธิ์  โปรดแสดงหน้าคูปองกับพนักงานก่อนทำการสั่งซื้อทุกครั้ง และสแกน QR Code ที่ระบบการขายที่หน้าร้าน  (POS) โดยไม่สามารถแคปเจอร์หน้าจอมาใช้งานได้ </li>
                                <li>เมื่อสแกนยืนยันการใช้สิทธิ์คูปอง ลูกค้าจะต้องใช้คูปองทันที  ไม่สามารถเก็บไว้ใช้ภายหลังได้ และระบบจะขึ้นคำว่า “Redeemed” (ใช้คูปองแล้ว) โดยอัตโนมัติ  พร้อมระบุวันและเวลาที่กดรับสิทธิ์</li>
                                <li>คูปองนี้  สามารถใช้สิทธิ์ได้เพียงครั้งเดียวเท่านั้น หากลูกค้าสแกนยืนยันการใช้คูปองแล้ว  จะไม่สามารถใช้คูปองได้อีกในทุกกรณี </li>
                                <li>คูปองไม่สามารถจำหน่าย  แลก ทอน เปลี่ยนเป็นเงินสดหรือของรางวัลอื่นได้ </li>
                                <li>คูปองส่วนลดไม่สามารถใช้ร่วมกับส่วนลดอื่นๆ  ของร้านได้ </li>
                                <li>เครื่องดื่มที่ได้รับแสตมป์จะต้องเป็นเครื่องดื่มที่ไม่ร่วมโปรโมชั่นใดๆ  รวมถึงต้องไม่มีการใช้ Voucher อื่นๆ อยู่ในใบเสร็จนั้น  ยกเว้นส่วนลดแก้วส่วนตัว (Personal Cup) หากลูกค้าใช้สิทธิ์คูปอง  เครื่องดื่มแก้วที่ลูกค้าจ่ายเงิน จะไม่นับรวมในการสะสมแสตมป์</li>
                                <li>เงื่อนไขเป็นไปตามที่บริษัทฯ  กำหนด </li>
                                <li>หากมีข้อสงสัยหรือต้องการสอบถามเงื่อนไขในกิจกรรม  กรุณาติดต่อได้ที่ ฝ่ายดูแลลูกค้า 02-339-0996 ทุกวันจันทร์ – อาทิตย์ ตั้งแต่เวลา  9.00 น. – 18.00 น.</li>
                            </ol>
                            <p><strong>คำจำกัดความในกิจกรรมนี้ </strong></p>
                            <ol>
                                <li>“กิจกรรม”  หมายถึง กิจกรรมแอปพลิเคชั่นไลน์ (Application  LINE) @StarbucksThailand ซึ่งจัดขึ้นโดย บริษัท คอฟฟี่ คอนเซ็ปต์  รีเทล จำกัด ตั้งแต่วันที่ 23 กันยายน 2567 – 13 ตุลาคม 2567  และคูปองจะหมดอายุการใช้งานในวันที่ 13 ตุลาคม 2567 เวลา 23.59 น.  หรือวันอื่นใดที่บริษัทฯ จะประกาศเปลี่ยนแปลงในอนาคต</li>
                                <li>"ข้อตกลงและเงื่อนไข”  (Terms and Conditions) หมายถึง  ข้อตกลงและเงื่อนไขฉบับนี้</li>
                                <li>"โฆษณา”  (Advertising) หรือ “การโฆษณา” (Ads) หมายถึง โฆษณาที่สร้างโดยผู้สนับสนุนเพื่อส่งเสริมการขาย  หรือเพื่อกิจกรรมการตลาดต่างๆ ของสินค้าหรือบริการของผู้สนับสนุนโดยผ่านเว็บไซต์</li>
                                <li>“แอปพลิเคชั่นไลน์  (Application LINE) @StarbucksThailand” หมายถึง  โปรแกรมที่ถูกสร้างขึ้นบนระบบปฏิบัติการแอนดรอยด์ (Android) ระบบปฏิบัติการไอโอเอส  (iOS) หรือระบบปฏิบัติการอื่นๆ  ที่อาจพัฒนาขึ้นในอนาคตเป็นสื่อกลาง  ให้ผู้สนับสนุนประกาศแคมเปญกิจกรรมการตลาดต่างๆ กำหนดสิทธิ์  กำหนดเงื่อนไขและข้อกำหนด รวมถึงการโฆษณาต่างๆ ให้กับผู้ใช้งาน (User) ที่ยินยอมตกลงการเข้าร่วมกิจกรรม</li>
                                <li>"แคมเปญ”  (Campaign) หมายถึง กิจกรรมส่งเสริมการตลาด  ที่ผู้สนับสนุนประกาศลงบนเว็บไซต์หรือโปรแกรมสำหรับให้ผู้ใช้งานร่วมกิจกรรม  หรือแลกรับของรางวัลได้ภายในระยะเวลาที่ผู้สนับสนุนกำหนด  ภายใต้ข้อตกลงและเงื่อนไขของแต่ละแคมเปญ</li>
                                <li>"ระบบ”  (System) หมายถึง  ฐานข้อมูลและโปรแกรมการทำงานของแอปพลิเคชั่นไลน์ @StarbucksThailand</li>
                                <li>"ผู้ใช้งาน”  (User) หมายถึง ท่านผู้ซึ่งเข้าชมและใช้งาน  รวมไปถึงการร่วมกิจกรรมต่างๆ บนแอปพลิเคชั่นไลน์ @StarbucksThailand</li>
                                <li>“บริษัทฯ”  และ/หรือ “Starbucks” หมายถึง  บริษัท คอฟฟี่ คอนเซ็ปต์ รีเทล จำกัด</li>
                                <li>“สิทธิ์”  (Privilege) หมายถึง สิทธิ์ที่ Starbucks เป็นผู้กำหนดในการได้มา เพื่อใช้ร่วมกิจกรรม แลกรับสินค้า บริการ  บัตรกำนัล คูปอง หรือส่วนลดจากแคมเปญที่ประกาศไว้</li>
                            </ol>
                            <p>ผู้เข้าร่วมกิจกรรมได้อ่านและเข้าใจ  และตกลงยินยอมรับทราบตามเงื่อนไขข้างต้นนี้  โดยท่านได้เลือกยอมรับข้อกำหนดและเงื่อนไขในการเข้าร่วมกิจกรรม  ก่อนเริ่มเข้าร่วมกิจกรรมเป็นที่เรียบร้อยแล้ว</p>
                        </div>
                    </div>
                </div>
                <div className="row p-0 m-0 justify-content-center index2">
                    <div className="col-6 mt-3 text-center">
                        <div className="d-grid">
                            <Link to="/HomeRegis" className="btn mt-0 mb-3 btn-register-2">ปิด</Link>
                        </div>
                    </div>
                </div>
                {/*<div className="position-relative">*/}
                {/*    <div className="bg-footer" style={{ bottom: '-85px' }}><img src={require("./media/bg-footer-01.png")} alt="Starbucks Privilege E-Coupons" className="img-fluid" /></div>*/}
                {/*</div>*/}
            </div>
        );
    }
}
